import getConfig from 'next/config'

type RuntimeConfig = {
  APP_ENV: string
  DATADOG_LOGS: boolean
  DATADOG_CLIENT_TOKEN: string
  LOGGER_LEVEL: string
  BUILD_ID: string
}

const getRuntimeConfig = (): RuntimeConfig => {
  return (getConfig() as { publicRuntimeConfig: RuntimeConfig })
    .publicRuntimeConfig
}

export default getRuntimeConfig
