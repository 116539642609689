import { useEffect } from 'react'

import { useRouter } from 'next/compat/router'
import queryString from 'query-string'

import type { AuctionFiltersState } from '@components/AuctionSearchProvider/types'
import type {
  SearchState,
  SearchAction,
} from '@components/BaseSearchProvider/types'
import stableStringify from '@helpers/stableStringify'

import {
  composeAuctionStateFromQueryParams,
  composeUrlParamsFromAuctionState,
} from './utils'

/**
 * This hook maintains consistency between query params and the internal
 * search state. It watches both the state and the set of query params, and
 * updates the other when one of them changes. When the state changes, a
 * shallow navigation is performed, meaning we don't go through next's data
 * loading logic, and a new entry is entered in the browser history. When
 * the url changes (e.g. the user hits the back button) we update the state
 * triggering a re-query (possibly hitting react-query's cache). The use of
 * `stableStringify` is significant here because both state and `router.query`
 * can change object identity without a change in any value.
 */
export const useAuctionStateURLRectification = (
  state: SearchState<AuctionFiltersState>,
  dispatch: (action: SearchAction<AuctionFiltersState>) => void,
  forceFilters?: Partial<AuctionFiltersState>
) => {
  const router = useRouter()

  if (!router) {
    throw new Error(
      'useAuctionStateURLRectification is not yet compatible with the app router \n' +
        'We must update this hook to fall back to next/navigation when the pages router object is undefined. \n' +
        'Until then, this hook will throw an error if it is used in app router contexts.'
    )
  }

  // Rectify URL when search state is updated
  useEffect(() => {
    const urlParams = composeUrlParamsFromAuctionState(
      state,
      Object.keys(forceFilters ?? {})
    )

    const url = Object.keys(urlParams).length
      ? `?${queryString.stringify(urlParams)}`
      : ''

    if (window.location.search !== url) {
      // `knownParams` are those that affect the search set (e.g. page,
      // order, filters). They need to be stripped from the existing set of
      // query params and replaced with the url params we just computed. Any
      // remaining params (e.g. path params, or utm param) need to be
      // propagated.
      const knownParams = new Set([
        'o',
        'p',
        'q',
        's',
        ...Object.keys(state.filters),
      ])

      const propagatedQuery = { ...router.query }
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      ;[...knownParams].forEach((key) => delete propagatedQuery[key])

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      router.push(
        {
          pathname: router.pathname,
          query: { ...propagatedQuery, ...urlParams },
        },
        undefined,
        { shallow: true }
      )
    }
    // We avoid triggering the effect when the query changes order (e.g., from
    // ?a=123&b=456 to ?b=456&a=123), since they are effectively the same,
    // and router.query isn't guaranteed to be stable.
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stableStringify(state)])

  // Rectify state when URL is changed
  useEffect(() => {
    dispatch({
      type: 'SET_STATE',
      state: composeAuctionStateFromQueryParams(router.query),
    })
    // We avoid triggering the effect when the query changes order (e.g., from
    // ?a=123&b=456 to ?b=456&a=123), since they are effectively the same,
    // and router.query isn't guaranteed to be stable.
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stableStringify(router.query)])
}
