import { useIntl } from 'react-intl'

import {
  useAuthState,
  useLogout,
  getStaticPath,
  useAccessTokenRoles,
} from '@b-stock/bstock-next'
import { SharedHeader, PortalName } from '@b-stock/bstock-react'

import { useAuctionSearch } from '@components/AuctionSearchProvider'
import useCompatibleRouter from '@helpers/useCompatibleRouter'

import { SearchBar } from './SearchBar/SearchBar'
import { SearchBarContainer } from './ui'
const logoUrl = 'https://bstock.com/all-auctions/'

type HeaderProps = {
  noSearchBar?: boolean
}

export const Header = ({ noSearchBar }: HeaderProps) => {
  const auctionsSearchCtx = useAuctionSearch()
  const { formatMessage } = useIntl()
  const router = useCompatibleRouter()
  const authState = useAuthState()
  const logout = useLogout()
  const person = authState?.person
  const accountId = person?.accountId
  const accountRoles = useAccessTokenRoles()
  const portalName =
    accountRoles?.buyer || accountRoles?.service
      ? PortalName.BUYER
      : PortalName.SELLER

  const onLogout = async () => {
    await logout()
    router.reload()
  }

  const searchAuctions = (query: string) => {
    auctionsSearchCtx.setQuery(query)
  }

  return (
    <SharedHeader
      logoAssetUrl={getStaticPath('images/bstock-logo_240x60.png')}
      baseUrl={logoUrl}
      firstName={person?.firstName}
      lastName={person?.lastName}
      personId={person?._id}
      accountId={accountId}
      // Developer Note: Categories and Conditions were removed from the Header as part of
      // the work done for TBD-794, because bstock-react was updated to a version where
      // they were removed (because of bicycle phase 2.3 needs).
      // Look at the Pagination MR for that ticket to find the previous implementation if
      // you are adding those back in to save yourself the time of re-implementing it from
      // scratch (assuming the old implementation is close enough to the new designs).
      onLogout={onLogout}
      portalName={portalName}
    >
      {noSearchBar ? null : (
        <SearchBarContainer>
          <SearchBar
            placeholder={formatMessage({ id: 'Header.searchAuctions' })}
            onSubmit={searchAuctions}
            initialValue={auctionsSearchCtx.state.query}
          />
        </SearchBarContainer>
      )}
    </SharedHeader>
  )
}

export default Header
