import { faGavel } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  FormattedMessage,
  CurrencyStringDollars,
  CurrencyStringCents,
} from '@b-stock/bstock-react'

import TextTransition from '@components/TextTransition'
import getCurrentPrice from '@helpers/auctions/getCurrentPrice'
import getIsWithinBuyNowWindow from '@helpers/auctions/getIsWithinBuyNowWindow'
import { BidStatus } from '@queries/listingSearchQuery/types'
import type { SearchResultWithAuction } from '@queries/listingSearchQuery/types'

import {
  Price,
  PriceContainer,
  BidAmountContainer,
  BidAmount,
  WinAt,
  BidsCountAndTime,
  TimeLeftContainer,
  BidsCount,
  VerticalDivider,
  Msrp,
  MsrpAmount,
} from './ui'
import PopcornInfo from '../PopcornInfo'
import AuctionDeadline from './AuctionDeadline'
import { isHot } from './util'
import type { UpdateAuctionDataFunction } from '../shared/useAuctionUpdates'
import useAuctionUpdates from '../shared/useAuctionUpdates'

export const SearchResultAuctionSection = ({
  result,
  updateAuctionData,
  onManualPollScheduled,
}: {
  result: SearchResultWithAuction
  updateAuctionData: UpdateAuctionDataFunction
  onManualPollScheduled: (nextManualPoll: Date | null) => void
}) => {
  const { auction, bidStatus, bid, endTime, listingId } = result
  const buyNowEndTime = auction.buyNow?.endTime

  const hasBids = bidStatus !== BidStatus.NO_BID
  const won = bidStatus === BidStatus.WON
  const losing = bidStatus === BidStatus.LOSING
  const lost = bidStatus === BidStatus.LOST

  const hot = isHot(endTime)
  const maxBidAmount = bid?.bidAmount || 0
  const isWithinBuyNowWindow = getIsWithinBuyNowWindow(auction)
  const currentPrice = getCurrentPrice(auction)

  useAuctionUpdates({
    auction,
    listingId,
    onManualPollScheduled,
    updateAuctionData,
  })

  return (
    <div>
      {hasBids ? (
        <BidAmountContainer>
          <>
            {!losing && !lost ? (
              <WinAt>
                {won ? (
                  <FormattedMessage id="SearchResultCard.wonAt" />
                ) : (
                  <FormattedMessage id="SearchResultCard.winningAt" />
                )}
              </WinAt>
            ) : null}

            <BidAmount $losing={losing || lost}>
              <FormattedMessage
                id={`SearchResultCard.${
                  losing || lost ? 'yourLastBidAmount' : 'maxBidAmount'
                }`}
                values={{ amount: maxBidAmount }}
              />
            </BidAmount>
          </>
        </BidAmountContainer>
      ) : null}
      {!hasBids && result.retailPrice ? (
        <Msrp>
          <FormattedMessage id="SearchResultCard.msrp" />
          <MsrpAmount>
            <CurrencyStringCents value={result.retailPrice} currency="USD" />
          </MsrpAmount>
        </Msrp>
      ) : null}

      <PriceContainer>
        <Price $won={won} $lost={lost} data-testid="price">
          <TextTransition transitionKey={currentPrice}>
            <CurrencyStringDollars
              value={currentPrice}
              currency="USD"
              omitDecimal
            />
          </TextTransition>
        </Price>
      </PriceContainer>

      <BidsCountAndTime>
        <TimeLeftContainer $hot={hot}>
          <AuctionDeadline
            formattedBidDeadline={null}
            deadline={
              isWithinBuyNowWindow && buyNowEndTime
                ? buyNowEndTime
                : result.endTime
            }
            distance
          />
        </TimeLeftContainer>

        {hot && <PopcornInfo amount={2} />}

        <VerticalDivider />

        <BidsCount>
          <FontAwesomeIcon icon={faGavel} />
          {result.numberOfBids || 0}
        </BidsCount>
      </BidsCountAndTime>
    </div>
  )
}
