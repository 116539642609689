import type { ReadonlyURLSearchParams } from 'next/navigation'

type Query = {
  [key: string]: string | string[] | undefined
}

const demandParam = (
  query: Query | ReadonlyURLSearchParams | undefined,
  param: string
): string => {
  if (typeof query === 'undefined') {
    throw new Error('`query` is undefined')
  }

  let value: string | string[] | undefined
  if (query instanceof URLSearchParams) {
    value = query.get(param) ?? undefined
  } else {
    value = query[param]
  }

  if (typeof value === 'string') {
    return value
  } else if (typeof value === 'object') {
    throw new Error(`Multiple values passed for param "${param}"`)
  } else {
    throw new Error(`No value passed for param "${param}"`)
  }
}

export default demandParam
