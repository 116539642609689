import { isFuture } from 'date-fns'

import type { OrchestratedAuction } from '@b-stock/bstock-next'

import type { SearchAuction } from '@queries/listingSearchQuery/types'

const getIsWithinBuyNowWindow = (
  auction: SearchAuction | OrchestratedAuction
) => {
  if (
    auction.buyNow?.enabled &&
    auction.buyNow.endTime &&
    !auction.closed &&
    !auction.canceled
  ) {
    return isFuture(auction.buyNow.endTime)
  }
  return false
}

export default getIsWithinBuyNowWindow
