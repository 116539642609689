import { useEffect } from 'react'

import Script from 'next/script'

import { useAuthState } from '@b-stock/bstock-next'

type Props = {
  apiKey: string
}

// Note: We can extract this global declaration out in global.d.ts file, if required and update ts-config if required.
declare global {
  interface Window {
    pendo?: {
      initialize?: (options: unknown) => void
      _q: unknown[]
    }
  }
}

const Pendo = ({ apiKey }: Props) => {
  const authState = useAuthState()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const initArg: Record<string, unknown> = {}

      if (authState) {
        initArg.visitor = {
          id: authState.person._id,
          name: `${authState.person.firstName} ${authState.person.lastName}`,
          email: authState.person.email,
        }
        initArg.account = {
          id: authState.person.accountId,
        }
      }

      if (window.pendo?.initialize) {
        window.pendo.initialize(initArg)
      } else {
        window.pendo = { _q: [['initialize', initArg]] }
      }
    }
  }, [authState])

  return <Script src={`https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`} />
}

export default Pendo
