import { faHourglassHalf } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import { FormattedMessage } from 'react-intl'
import { styled } from 'styled-components'

import { Countdown, useIsPast } from '@b-stock/bstock-next'
import { parseOptionalISODate } from '@b-stock/bstock-react'
import { Typography } from '@b-stock/bstock-react/design-system'

export const Deadline = styled.span`
  ${Typography.Body4}
  align-items: center;
  display: flex;
  gap: 4px;
  line-height: 1.375rem;
  white-space: nowrap;
`

const AuctionDeadline = ({
  formattedBidDeadline,
  deadline,
  distance,
}: {
  formattedBidDeadline: string | null
  deadline?: string
  distance?: boolean
}) => {
  const parsed = parseOptionalISODate(deadline)
  const isDeadlinePast = useIsPast(parsed)

  if (!parsed) return null

  return (
    <Deadline data-testid="deadline">
      {formattedBidDeadline ? (
        <>
          <FontAwesomeIcon icon={faHourglassHalf} />
          <span>
            <FormattedMessage
              id="Auction.details.statsBar.countdown"
              values={{
                countdown: formattedBidDeadline,
              }}
            />
          </span>
        </>
      ) : distance ? (
        <>
          <FontAwesomeIcon icon={faHourglassHalf} />
          <span>
            {isDeadlinePast ? (
              <>
                <FormattedMessage
                  id="Auction.details.endedTimeAgo"
                  values={{
                    time: <Countdown target={parsed} absoluteDifference />,
                  }}
                />
              </>
            ) : (
              <Countdown target={parsed} />
            )}
          </span>
        </>
      ) : (
        <FormattedMessage
          id="Auction.details.order.endedAt"
          values={{
            endedAt: format(parsed, 'MM/dd/yy HH:mm zzz'),
          }}
        />
      )}
    </Deadline>
  )
}

export default AuctionDeadline
