import { useMemo, useRef, useState } from 'react'

import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import { Button, Input, FormattedMessage } from '@b-stock/bstock-react'
import { designColors } from '@b-stock/bstock-react/theme'

import OnlyableCheckbox from '@components/SearchFilters/shared/OnlyableCheckbox'

type ObjType = Partial<Record<string, string[]>>

interface IProps {
  allSellers?: {
    [key: string]: number
  }
  selected: Set<string>
  onChange: (value: Set<string>) => void
  onClose?: () => void
}

interface AlphabeticalFilterContent {
  items?: {
    [key: string]: number
  }
  selected: Set<string>
  handleChange: (key: string) => void
  handleOnlyClick: (key: string) => void
}

const Wrapper = styled.div`
  position: relative;
  width: 820px;
  padding: 45px 0 76px 48px;
  overflow: hidden;
  @media (max-width: 1000px) {
    width: 100%;
    padding: 24px 16px 76px;
  }
`

const Content = styled.div`
  display: flex;
  max-height: 400px;
  overflow: auto;
  @media (max-width: 1000px) {
    max-height: calc(100vh - 285px);
  }
`

const AlphabetContainer = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
  text-transform: capitalize;
  li {
    font-size: 1rem;
    line-height: 1.75rem;
    color: ${designColors.neutral.darkGray};
    cursor: pointer;
  }
`

const SellersContainer = styled.div`
  height: 100%;
  width: calc(100% - 0.125rem);
  margin-left: 1rem;
  padding-left: 1rem;
`

const LetterSellerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${designColors.neutral.mediumGray};
  li {
    list-style: none;
  }
`

const InputContainer = styled.div`
  position: relative;
`

const StyledInput = styled(Input)`
  width: calc(100% - 50px);
  margin-bottom: 34px;
  padding-left: 40px;
  @media (max-width: 1000px) {
    width: 100%;
  }
`

const InputIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 12px;
  left: 15px;
  height: 19px;
`

const Actions = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 76px;
  padding: 15px 48px 17px;
  border-top: 1px solid ${designColors.neutral.mediumGray};
`

const Divider = styled.div`
  width: 0;
  position: absolute;
  left: 79px;
  height: 400px;
  border-left: 1px solid ${designColors.neutral.mediumGray};
  @media (max-width: 1000px) {
    height: calc(100vh - 285px);
    left: 50px;
  }
`

const StyledButton = styled(Button)`
  @media (max-width: 768px) {
    padding: 0 24px;
  }
`

export const AlphabeticalFilterContent = ({
  items,
  selected,
  handleChange,
  handleOnlyClick,
}: AlphabeticalFilterContent) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [inputValue, setInputValue] = useState<string>('')
  const letters = useMemo(() => {
    const letters: ObjType = {}

    if (items) {
      Object.keys(items)
        .sort((a, b) => a.localeCompare(b))
        .forEach((item) => {
          const letter = item[0].toLowerCase().replace(/[^a-z]/gi, '#')
          if (letters[letter]?.length) {
            letters[letter].push(item)
          } else {
            letters[letter] = [item]
          }
        })
    }

    return letters
  }, [items])

  const filterByLetter = (letter: string) => {
    const element = document.getElementById(letter)
    if (element && containerRef.current) {
      containerRef.current.scrollTo({
        top: element.offsetTop - 123,
        behavior: 'smooth',
      })
    }
  }

  return (
    <>
      <InputContainer>
        <InputIcon icon={faSearch} />
        <StyledInput
          placeholder={'Find a Seller'}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      </InputContainer>
      <Content ref={containerRef}>
        <AlphabetContainer>
          {Object.keys(letters).map((letter) => (
            <li key={letter} onClick={() => filterByLetter(letter)}>
              {letter}
            </li>
          ))}
        </AlphabetContainer>
        <Divider />
        <SellersContainer>
          {Object.keys(letters).map((letter) => {
            if (
              !inputValue ||
              inputValue[0].toLowerCase() === letter ||
              (letter === '#' && /[^a-z]/gi.test(inputValue[0]))
            ) {
              const sellerNameRegex = new RegExp(`^${inputValue}`, 'i')

              return (
                <LetterSellerWrapper key={letter} id={letter}>
                  {letters[letter]?.map((seller) => {
                    if (!inputValue || sellerNameRegex.test(seller)) {
                      return (
                        <OnlyableCheckbox
                          key={seller}
                          displayName={seller}
                          checked={selected.has(seller)}
                          onChange={() => handleChange(seller)}
                          onOnly={() => handleOnlyClick(seller)}
                        />
                      )
                    }
                  })}
                </LetterSellerWrapper>
              )
            }
          })}
        </SellersContainer>
      </Content>
    </>
  )
}

export const SellerFilterModal = ({
  allSellers,
  selected,
  onChange,
  onClose,
}: IProps) => {
  const submit = () => {
    close()
  }

  const handleChange = (key: string) => {
    const clone = new Set([...(typeof selected !== 'string' ? selected : [])])
    if (clone.has(key)) {
      clone.delete(key)
    } else {
      clone.add(key)
    }
    onChange(clone)
  }

  const handleOnlyClick = (key: string) => {
    const clone = new Set([...(typeof selected !== 'string' ? selected : [])])
    clone.clear()
    clone.add(key)
    onChange(clone)
  }

  const close = () => {
    onClose?.()
  }

  return (
    <Wrapper>
      <AlphabeticalFilterContent
        items={allSellers}
        selected={selected}
        handleOnlyClick={handleOnlyClick}
        handleChange={handleChange}
      />
      <Actions>
        <StyledButton onClick={close} size={'large'}>
          <FormattedMessage id={'close'} />
        </StyledButton>
        <StyledButton onClick={submit} size={'large'}>
          <FormattedMessage id={'submit'} />
        </StyledButton>
      </Actions>
    </Wrapper>
  )
}
