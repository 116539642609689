import type { SearchResult } from '@queries/listingSearchQuery/types'

import AuctionDeadline from './AuctionDeadline'
import { BidsCountAndTime, TimeLeftContainer } from './ui'
import { isHot } from './util'

export const SearchResultOfferingSection = ({
  result,
}: {
  result: SearchResult
}) => {
  const { endTime } = result

  const hot = isHot(endTime)

  return (
    <div>
      <BidsCountAndTime>
        <TimeLeftContainer $hot={hot}>
          <AuctionDeadline
            formattedBidDeadline={null}
            deadline={endTime}
            distance
          />
        </TimeLeftContainer>
      </BidsCountAndTime>
    </div>
  )
}
