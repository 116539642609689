import { useRouter as usePagesRouter } from 'next/compat/router'
import { useRouter } from 'next/navigation'

// Simplified router interface that will work with both the nextjs pages router
// and the app router.
const useCompatibleRouter = () => {
  const pagesRouter = usePagesRouter()
  const router = useRouter()

  return {
    push: (url: string) => {
      if (pagesRouter) {
        void pagesRouter.push(url)
      } else {
        router.push(url)
      }
    },
    replace: (url: string) => {
      if (pagesRouter) {
        void pagesRouter.replace(url)
      } else {
        router.replace(url)
      }
    },
    reload: () => {
      if (pagesRouter) {
        pagesRouter.reload()
      } else {
        window.location.reload()
      }
    },
  }
}

export default useCompatibleRouter
