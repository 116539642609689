import type React from 'react'

import { faMapMarkerAlt, faSync } from '@fortawesome/pro-regular-svg-icons'
import Link from 'next/link'

import { getStaticPath } from '@b-stock/bstock-next'
import { FormattedMessage } from '@b-stock/bstock-react'

import Tag from '@components/features/auction/details/Bid/Tag'
import { BidStatus } from '@queries/listingSearchQuery/types'
import {
  type SearchResult,
  isSearchResultWithAuction,
} from '@queries/listingSearchQuery/types'

import { SearchResultAuctionSection } from './SearchResultAuctionSection'
import { SearchResultOfferingSection } from './SearchResultOfferingSection'
import {
  AuctionImage,
  Title,
  Container,
  SalvageTag,
  SalvageIcon,
  ImageContainer,
  Chips,
  ImageOverlay,
  ImageFooter,
  SellerContainer,
  SoldByContainer,
  SoldByLabel,
  SellerName,
  LocationInfo,
  LocationIcon,
  CardDescription,
} from './ui'
import type { UpdateAuctionDataFunction } from '../shared/useAuctionUpdates'

export type SearchResultCardProps = {
  result: SearchResult
  className?: string
  showSoldBy?: boolean
  updateAuctionData: UpdateAuctionDataFunction
  onManualPollScheduled: (nextManualPoll: Date | null) => void
}

const SearchResultCard: React.FC<SearchResultCardProps> = ({
  result,
  className,
  showSoldBy,
  updateAuctionData,
  onManualPollScheduled,
}) => {
  const { bidStatus = '', categories } = result
  const hasBids = bidStatus !== BidStatus.NO_BID

  const salvage = !!categories?.length && categories[0] === 'salvage'
  const detailsUrl = `/listings/details/${result.listingId}`

  // we assume offering if not auction
  const isOfferingResult = !result.auction

  return (
    <Link href={detailsUrl}>
      <Container className={className} data-testid="search-result-card">
        <ImageContainer>
          <Chips>
            {bidStatus && hasBids && (
              <Tag
                bidStatus={result.bidStatus}
                noMargin
                order={
                  // We intentionally don't check for or show if the order
                  // has been created or not in this context.
                  null
                }
              />
            )}

            {salvage && (
              <SalvageTag>
                <SalvageIcon icon={faSync} />
                <FormattedMessage
                  id="SearchResultCard.salvage"
                  defaultMessage="SearchResultCard.salvage"
                />
              </SalvageTag>
            )}
          </Chips>

          <AuctionImage
            src={
              result.primaryImageUrls?.medium ??
              getStaticPath('images/placeholder-image.png')
            }
          />

          <ImageOverlay>
            <ImageFooter>
              <SoldByContainer>
                <SellerContainer>
                  {showSoldBy && (
                    <>
                      <SoldByLabel>
                        <FormattedMessage id="SearchResultCard.soldBy" />
                      </SoldByLabel>
                      <SellerName>{result.siteName}</SellerName>
                    </>
                  )}
                </SellerContainer>
              </SoldByContainer>
            </ImageFooter>
          </ImageOverlay>
        </ImageContainer>

        <CardDescription>
          <div>
            <Title>{result.title}</Title>

            <LocationInfo>
              <LocationIcon icon={faMapMarkerAlt} />

              <span>
                {result.sellerCity}, {result.sellerRegion}
              </span>
            </LocationInfo>
          </div>

          {isSearchResultWithAuction(result) && (
            <SearchResultAuctionSection
              result={result}
              updateAuctionData={updateAuctionData}
              onManualPollScheduled={onManualPollScheduled}
            />
          )}
          {isOfferingResult && <SearchResultOfferingSection {...{ result }} />}
        </CardDescription>
      </Container>
    </Link>
  )
}

export default SearchResultCard
