import type * as React from 'react'

import { styled, keyframes, css } from 'styled-components'

import { getStaticPath } from '@b-stock/bstock-next'

import {
  Container,
  ImageContainer,
  AuctionImage,
} from '@components/features/auction/SearchResultCard/ui'

const LoadingContainer = styled.div`
  display: grid;
  grid-template-columns: 4fr 1rem 2rem 2rem 1fr;
  grid-template-rows: 0.5rem 1rem 1rem 1rem 1rem 0 1rem 1rem;
  grid-gap: 1rem;
`

const shine = keyframes`
 0% {
    background-position-x: 0%;
 }
 100% {
    background-position-x: -200%;
 }
`

type AnimatedLoadingBlockProps = {
  $animationAllowed?: boolean
}

export const AnimatedLoadingBlock = styled.div<AnimatedLoadingBlockProps>`
  background: #eee;
  border-radius: 5px;
  ${({ $animationAllowed }) =>
    $animationAllowed &&
    css`
      animation-name: ${shine};
      animation-duration: 1.6s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;

      background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
      background-size: 200% 100%;
    `};
`

const LoadingBlock = styled(AnimatedLoadingBlock)`
  &:nth-of-type(1) {
    grid-column: 1/6;
    grid-row: 2/3;
  }

  &:nth-of-type(2) {
    grid-column: 1/3;
    grid-column: 1/6;
    grid-row: 3/4;
  }

  &:nth-of-type(3) {
    grid-column: 1/2;
    grid-column: 1/4;
    grid-row: 4/5;
  }

  &:nth-of-type(4) {
    grid-row: 5/6;
    grid-column: 1/3;
    grid-column: 1/6;
  }

  &:nth-of-type(5) {
    grid-row: 7/9;
    grid-column: 1/3;
  }

  &:nth-of-type(6) {
    grid-row: 7/9;
    grid-column: 3/5;
  }
`

export type LoadingSearchResultCardProps = {
  animationAllowed?: boolean
}

const LoadingAuction: React.FC<LoadingSearchResultCardProps> = ({
  animationAllowed,
}) => {
  return (
    <Container>
      <ImageContainer>
        <AuctionImage
          src={getStaticPath('images/placeholder-auction-image.png')}
        />
      </ImageContainer>
      <LoadingContainer>
        <LoadingBlock $animationAllowed={animationAllowed} />
        <LoadingBlock $animationAllowed={animationAllowed} />
        <LoadingBlock $animationAllowed={animationAllowed} />
        <LoadingBlock $animationAllowed={animationAllowed} />
        <LoadingBlock $animationAllowed={animationAllowed} />
        <LoadingBlock $animationAllowed={animationAllowed} />
      </LoadingContainer>
    </Container>
  )
}

export default LoadingAuction
